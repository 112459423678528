const andmed = [
  { country: 'Russia', riik: 'Venemaa', area: '17098246', fileName: 'Russia.png' },
  { country: 'Canada', riik: 'Kanada', area: '9984670', fileName: 'Canada.png' },
  { country: 'China', riik: 'Hiina', area: '9596961', fileName: 'China.png' },
  { country: 'United States of America', riik: 'Ameerika Ühendriigid', area: '9525067', fileName: 'United_States.png' },
  { country: 'Brazil', riik: 'Brasiilia', area: '8515767', fileName: 'Brazil.png' },
  { country: 'Australia', riik: 'Austraalia', area: '7692024', fileName: 'Australia.png' },
  { country: 'India', riik: 'India', area: '3287263', fileName: 'India.png' },
  { country: 'Argentina', riik: 'Argentina', area: '2780400', fileName: 'Argentina.png' },
  { country: 'Kazakhstan', riik: 'Kasahstan', area: '2724900', fileName: 'Kazakhstan.png' },
  { country: 'Algeria', riik: 'Alžeeria', area: '2381741', fileName: 'Algeria.png' },
  {
    country: 'Democratic Republic of the Congo',
    riik: 'Kongo DV',
    area: '2344858',
    fileName: 'Democratic_Republic_of_the_Congo.png'
  },
  { country: 'Denmark', riik: 'Taani', area: '42933', fileName: 'Denmark.png' },
  { country: 'Saudi Arabia', riik: 'Saudi Araabia', area: '2149690', fileName: 'Saudi_Arabia.png' },
  { country: 'Mexico', riik: 'Mehhiko', area: '1964375', fileName: 'Mexico.png' },
  { country: 'Indonesia', riik: 'Indoneesia', area: '1910931', fileName: 'Indonesia.png' },
  { country: 'Sudan', riik: 'Sudaan', area: '1861484', fileName: 'Sudan.png' },
  { country: 'Libya', riik: 'Liibüa', area: '1759540', fileName: 'Libya.png' },
  { country: 'Iran', riik: 'Iraan', area: '1648195', fileName: 'Iran.png' },
  { country: 'Mongolia', riik: 'Mongoolia', area: '1564110', fileName: 'Mongolia.png' },
  { country: 'Peru', riik: 'Peruu', area: '1285216', fileName: 'Peru.png' },
  { country: 'Chad', riik: 'Tšaad', area: '1284000', fileName: 'Chad.png' },
  { country: 'Niger', riik: 'Niiger', area: '1267000', fileName: 'Niger.png' },
  { country: 'Angola', riik: 'Angola', area: '1246700', fileName: 'Angola.png' },
  { country: 'Mali', riik: 'Mali', area: '1240192', fileName: 'Mali.png' },
  { country: 'South Africa', riik: 'Lõuna-Aafrika Vabariik', area: '1221037', fileName: 'South_Africa.png' },
  { country: 'Colombia', riik: 'Colombia', area: '1141748', fileName: 'Colombia.png' },
  { country: 'Ethiopia', riik: 'Etioopia', area: '1104300', fileName: 'Ethiopia.png' },
  { country: 'Bolivia', riik: 'Boliivia', area: '1098581', fileName: 'Bolivia.png' },
  { country: 'Mauritania', riik: 'Mauritaania', area: '1030700', fileName: 'Mauritania.png' },
  { country: 'Egypt', riik: 'Egiptus', area: '1002450', fileName: 'Egypt.png' },
  { country: 'Tanzania', riik: 'Tansaania', area: '945087', fileName: 'Tanzania.png' },
  { country: 'Nigeria', riik: 'Nigeeria', area: '923768', fileName: 'Nigeria.png' },
  { country: 'Venezuela', riik: 'Venezuela', area: '916445', fileName: 'Venezuela.png' },
  { country: 'Pakistan', riik: 'Pakistan', area: '907843', fileName: 'Pakistan.png' },
  { country: 'Namibia', riik: 'Namiibia', area: '825615', fileName: 'Namibia.png' },
  { country: 'Mozambique', riik: 'Mosambiik', area: '801590', fileName: 'Mozambique.png' },
  { country: 'Turkey', riik: 'Türgi', area: '783562', fileName: 'Turkey.png' },
  { country: 'Chile', riik: 'Tšiili', area: '756102', fileName: 'Chile.png' },
  { country: 'Zambia', riik: 'Sambia', area: '752612', fileName: 'Zambia.png' },
  { country: 'Myanmar', riik: 'Myanmar', area: '676578', fileName: 'Myanmar.png' },
  { country: 'Afghanistan', riik: 'Afganistan', area: '652230', fileName: 'Afghanistan.png' },
  { country: 'South Sudan', riik: 'Lõuna-Sudaan', area: '644329', fileName: 'South_Sudan.png' },
  { country: 'Somalia', riik: 'Somaalia', area: '637657', fileName: 'Somalia.png' },
  {
    country: 'Central African Republic',
    riik: 'Kesk-Aafrika Vabariik',
    area: '622984',
    fileName: 'Central_African_Republic.png'
  },
  { country: 'Ukraine', riik: 'Ukraina', area: '603500', fileName: 'Ukraine.png' },
  { country: 'Madagascar', riik: 'Madagaskar', area: '587041', fileName: 'Madagascar.png' },
  { country: 'Botswana', riik: 'Botswana', area: '581730', fileName: 'Botswana.png' },
  { country: 'Kenya', riik: 'Keenia', area: '580367', fileName: 'Kenya.png' },
  { country: 'France', riik: 'Prantsusmaa', area: '543940', fileName: 'France.png' },
  { country: 'Yemen', riik: 'Jeemen', area: '527968', fileName: 'Yemen.png' },
  { country: 'Thailand', riik: 'Tai', area: '513120', fileName: 'Thailand.png' },
  { country: 'Spain', riik: 'Hispaania', area: '505992', fileName: 'Spain.png' },
  { country: 'Turkmenistan', riik: 'Türkmenistan', area: '488100', fileName: 'Turkmenistan.png' },
  { country: 'Cameroon', riik: 'Kamerun', area: '475442', fileName: 'Cameroon.png' },
  { country: 'Papua New Guinea', riik: 'Paapua Uus-Guinea', area: '462840', fileName: 'Papua_New_Guinea.png' },
  { country: 'Sweden', riik: 'Rootsi', area: '450295', fileName: 'Sweden.png' },
  { country: 'Uzbekistan', riik: 'Usbekistan', area: '447400', fileName: 'Uzbekistan.png' },
  { country: 'Morocco', riik: 'Maroko', area: '446550', fileName: 'Morocco.png' },
  { country: 'Iraq', riik: 'Iraak', area: '438317', fileName: 'Iraq.png' },
  { country: 'Paraguay', riik: 'Paraguay', area: '406752', fileName: 'Paraguay.png' },
  { country: 'Zimbabwe', riik: 'Zimbabwe', area: '390757', fileName: 'Zimbabwe.png' },
  { country: 'Japan', riik: 'Jaapan', area: '377975', fileName: 'Japan.png' },
  { country: 'Germany', riik: 'Saksamaa', area: '357114', fileName: 'Germany.png' },
  { country: 'Republic of the Congo', riik: 'Kongo', area: '342000', fileName: 'Republic_of_the_Congo.png' },
  { country: 'Finland', riik: 'Soome', area: '338424', fileName: 'Finland.png' },
  { country: 'Vietnam', riik: 'Vietnam', area: '331212', fileName: 'Vietnam.png' },
  { country: 'Malaysia', riik: 'Malaisia', area: '330803', fileName: 'Malaysia.png' },
  { country: 'Norway', riik: 'Norra', area: '323802', fileName: 'Norway.png' },
  { country: 'Ivory Coast', riik: 'Elevandiluurannik', area: '322463', fileName: 'Ivory_Coast.png' },
  { country: 'Poland', riik: 'Poola', area: '312696', fileName: 'Poland.png' },
  { country: 'Oman', riik: 'Omaan', area: '309500', fileName: 'Oman.png' },
  { country: 'Italy', riik: 'Itaalia', area: '301339', fileName: 'Italy.png' },
  { country: 'Philippines', riik: 'Filipiinid', area: '300000', fileName: 'Philippines.png' },
  { country: 'Ecuador', riik: 'Ecuador', area: '276841', fileName: 'Ecuador.png' },
  { country: 'Burkina Faso', riik: 'Burkina Faso', area: '274222', fileName: 'Burkina_Faso.png' },
  { country: 'New Zealand', riik: 'Uus-Meremaa', area: '270467', fileName: 'New_Zealand.png' },
  { country: 'Gabon', riik: 'Gabon', area: '267668', fileName: 'Gabon.png' },
  {
    country: 'Sahrawi Arab Democratic Republic',
    riik: 'Sahara Araabia Demokraatlik Vabariik',
    area: '266000',
    fileName: 'Sahrawi_Arab_Democratic_Republic.png'
  },
  { country: 'Guinea', riik: 'Guinea', area: '245857', fileName: 'Guinea.png' },
  { country: 'United Kingdom', riik: 'Suurbritannia', area: '242495', fileName: 'United_Kingdom.png' },
  { country: 'Uganda', riik: 'Uganda', area: '241550', fileName: 'Uganda.png' },
  { country: 'Ghana', riik: 'Ghana', area: '238533', fileName: 'Ghana.png' },
  { country: 'Romania', riik: 'Rumeenia', area: '238397', fileName: 'Romania.png' },
  { country: 'Laos', riik: 'Laos', area: '236800', fileName: 'Laos.png' },
  { country: 'Guyana', riik: 'Guajaana', area: '214969', fileName: 'Guyana.png' },
  { country: 'Belarus', riik: 'Valgevene', area: '207600', fileName: 'Belarus.png' },
  { country: 'Kyrgyzstan', riik: 'Kõrgõzstan', area: '199951', fileName: 'Kyrgyzstan.png' },
  { country: 'Senegal', riik: 'Senegal', area: '196722', fileName: 'Senegal.png' },
  { country: 'Syria', riik: 'Süüria', area: '185180', fileName: 'Syria.png' },
  { country: 'Cambodia', riik: 'Kambodža', area: '181035', fileName: 'Cambodia.png' },
  { country: 'Uruguay', riik: 'Uruguay', area: '176215', fileName: 'Uruguay.png' },
  { country: 'Somaliland', riik: 'Somaalimaa', area: '176120', fileName: 'Somaliland.png' },
  { country: 'Suriname', riik: 'Suriname', area: '163820', fileName: 'Suriname.png' },
  { country: 'Tunisia', riik: 'Tuneesia', area: '163610', fileName: 'Tunisia.png' },
  { country: 'Bangladesh', riik: 'Bangladesh', area: '148460', fileName: 'Bangladesh.png' },
  { country: 'Nepal', riik: 'Nepal', area: '147181', fileName: 'Nepal.png' },
  { country: 'Tajikistan', riik: 'Tadžikistan', area: '143100', fileName: 'Tajikistan.png' },
  { country: 'Greece', riik: 'Kreeka', area: '131957', fileName: 'Greece.png' },
  { country: 'Nicaragua', riik: 'Nicaragua', area: '130373', fileName: 'Nicaragua.png' },
  { country: 'North Korea', riik: 'Põhja-Korea', area: '120540', fileName: 'North_Korea.png' },
  { country: 'Malawi', riik: 'Malawi', area: '118484', fileName: 'Malawi.png' },
  { country: 'Eritrea', riik: 'Eritrea', area: '117600', fileName: 'Eritrea.png' },
  { country: 'Benin', riik: 'Benin', area: '114763', fileName: 'Benin.png' },
  { country: 'Honduras', riik: 'Honduras', area: '112492', fileName: 'Honduras.png' },
  { country: 'Liberia', riik: 'Libeeria', area: '111369', fileName: 'Liberia.png' },
  { country: 'Bulgaria', riik: 'Bulgaaria', area: '111002', fileName: 'Bulgaria.png' },
  { country: 'Cuba', riik: 'Kuuba', area: '109884', fileName: 'Cuba.png' },
  { country: 'Guatemala', riik: 'Guatemala', area: '108889', fileName: 'Guatemala.png' },
  { country: 'Iceland', riik: 'Island', area: '103000', fileName: 'Iceland.png' },
  { country: 'South Korea', riik: 'Lõuna-Korea', area: '100210', fileName: 'South_Korea.png' },
  { country: 'Hungary', riik: 'Ungari', area: '93028', fileName: 'Hungary.png' },
  { country: 'Portugal', riik: 'Portugal', area: '92226', fileName: 'Portugal.png' },
  { country: 'Jordan', riik: 'Jordaania', area: '89342', fileName: 'Jordan.png' },
  { country: 'Serbia', riik: 'Serbia', area: '88361', fileName: 'Serbia.png' },
  { country: 'Azerbaijan', riik: 'Aserbaidžaan', area: '86600', fileName: 'Azerbaijan.png' },
  { country: 'Austria', riik: 'Austria', area: '83871', fileName: 'Austria.png' },
  {
    country: 'United Arab Emirates',
    riik: 'Araabia Ühendemiraadid',
    area: '83600',
    fileName: 'United_Arab_Emirates.png'
  },
  { country: 'Czechia', riik: 'Tšehhi', area: '78865', fileName: 'Czechia.png' },
  { country: 'Panama', riik: 'Panama', area: '75417', fileName: 'Panama.png' },
  { country: 'Sierra Leone', riik: 'Sierra Leone', area: '71740', fileName: 'Sierra_Leone.png' },
  { country: 'Ireland', riik: 'Iirimaa', area: '70273', fileName: 'Ireland.png' },
  { country: 'Georgia', riik: 'Gruusia', area: '69700', fileName: 'Georgia.png' },
  { country: 'Sri Lanka', riik: 'Sri Lanka', area: '65610', fileName: 'Sri_Lanka.png' },
  { country: 'Lithuania', riik: 'Leedu', area: '65300', fileName: 'Lithuania.png' },
  { country: 'Latvia', riik: 'Läti', area: '64559', fileName: 'Latvia.png' },
  { country: 'Togo', riik: 'Togo', area: '56785', fileName: 'Togo.png' },
  { country: 'Croatia', riik: 'Horvaatia', area: '56594', fileName: 'Croatia.png' },
  {
    country: 'Bosnia and Herzegovina',
    riik: 'Bosnia ja Hertsegoviina',
    area: '51209',
    fileName: 'Bosnia_and_Herzegovina.png'
  },
  { country: 'Costa Rica', riik: 'Costa Rica', area: '51100', fileName: 'Costa_Rica.png' },
  { country: 'Slovakia', riik: 'Slovakkia', area: '49037', fileName: 'Slovakia.png' },
  { country: 'Dominican Republic', riik: 'Dominikaani Vabariik', area: '48671', fileName: 'Dominican_Republic.png' },
  { country: 'Estonia', riik: 'Eesti', area: '45227', fileName: 'Estonia.png' },
  { country: 'Denmark', riik: 'Taani', area: '43094', fileName: 'Denmark.png' },
  { country: 'Netherlands', riik: 'Holland', area: '41850', fileName: 'Netherlands.png' },
  { country: 'Switzerland', riik: 'Šveits', area: '41284', fileName: 'Switzerland.png' },
  { country: 'Bhutan', riik: 'Bhutan', area: '38394', fileName: 'Bhutan.png' },
  { country: 'Taiwan', riik: 'Taiwan', area: '36193', fileName: 'Taiwan.png' },
  { country: 'Guinea-Bissau', riik: 'Guinea-Bissau', area: '36125', fileName: 'Guinea-Bissau.png' },
  { country: 'Moldova', riik: 'Moldova', area: '33846', fileName: 'Moldova.png' },
  { country: 'Belgium', riik: 'Belgia', area: '30528', fileName: 'Belgium.png' },
  { country: 'Lesotho', riik: 'Lesotho', area: '30355', fileName: 'Lesotho.png' },
  { country: 'Armenia', riik: 'Armeenia', area: '29743', fileName: 'Armenia.png' },
  { country: 'Solomon Islands', riik: 'Saalomoni Saared', area: '28896', fileName: 'Solomon_Islands.png' },
  { country: 'Albania', riik: 'Albaania', area: '28748', fileName: 'Albania.png' },
  { country: 'Equatorial Guinea', riik: 'Ekvatoriaal-Guinea', area: '28051', fileName: 'Equatorial_Guinea.png' },
  { country: 'Burundi', riik: 'Burundi', area: '27834', fileName: 'Burundi.png' },
  { country: 'Haiti', riik: 'Haiti', area: '27750', fileName: 'Haiti.png' },
  { country: 'Rwanda', riik: 'Rwanda', area: '26338', fileName: 'Rwanda.png' },
  { country: 'North Macedonia', riik: 'Põhja-Makedoonia', area: '25713', fileName: 'North_Macedonia.png' },
  { country: 'Djibouti', riik: 'Djibouti', area: '23200', fileName: 'Djibouti.png' },
  { country: 'Belize', riik: 'Belize', area: '22966', fileName: 'Belize.png' },
  { country: 'El Salvador', riik: 'El Salvador', area: '21041', fileName: 'El_Salvador.png' },
  { country: 'Israel', riik: 'Iisrael', area: '20770', fileName: 'Israel.png' },
  { country: 'Slovenia', riik: 'Sloveenia', area: '20273', fileName: 'Slovenia.png' },
  { country: 'Fiji', riik: 'Fidži', area: '18272', fileName: 'Fiji.png' },
  { country: 'Kuwait', riik: 'Kuveit', area: '17818', fileName: 'Kuwait.png' },
  { country: 'Eswatini', riik: 'Eswatini', area: '17364', fileName: 'Eswatini.png' },
  { country: 'East Timor', riik: 'Ida-Timor', area: '14919', fileName: 'East_Timor.png' },
  { country: 'The Bahamas', riik: 'Bahama', area: '13943', fileName: 'The_Bahamas.png' },
  { country: 'Montenegro', riik: 'Montenegro', area: '13812', fileName: 'Montenegro.png' },
  { country: 'Vanuatu', riik: 'Vanuatu', area: '12189', fileName: 'Vanuatu.png' },
  { country: 'Qatar', riik: 'Katar', area: '11586', fileName: 'Qatar.png' },
  { country: 'Gambia', riik: 'Gambia', area: '11295', fileName: 'Gambia.png' },
  { country: 'Jamaica', riik: 'Jamaica', area: '10991', fileName: 'Jamaica.png' },
  { country: 'Kosovo', riik: 'Kosovo', area: '10887', fileName: 'Kosovo.png' },
  { country: 'Lebanon', riik: 'Liibanon', area: '10452', fileName: 'Lebanon.png' },
  { country: 'Cyprus', riik: 'Küpros', area: '9251', fileName: 'Cyprus.png' },
  { country: 'State of Palestine', riik: 'Palestiina', area: '6020', fileName: 'State_of_Palestine.png' },
  { country: 'Brunei', riik: 'Brunei', area: '5765', fileName: 'Brunei.png' },
  { country: 'Trinidad and Tobago', riik: 'Trinidad ja Tobago', area: '5130', fileName: 'Trinidad_and_Tobago.png' },
  { country: 'Cape Verde', riik: 'Roheneemesaared', area: '4033', fileName: 'Cape_Verde.png' },
  { country: 'Samoa', riik: 'Samoa', area: '2842', fileName: 'Samoa.png' },
  { country: 'Luxembourg', riik: 'Luksemburg', area: '2586', fileName: 'Luxembourg.png' },
  { country: 'Mauritius', riik: 'Mauritius', area: '2040', fileName: 'Mauritius.png' },
  { country: 'Comoros', riik: 'Komoorid', area: '1862', fileName: 'Comoros.png' },
  {
    country: 'São Tomé and Príncipe',
    riik: 'São Tomé ja Príncipe',
    area: '964',
    fileName: 'Sao_Tome_and_Principe.png'
  },
  { country: 'Kiribati', riik: 'Kiribati', area: '811', fileName: 'Kiribati.png' },
  { country: 'Bahrain', riik: 'Bahrein', area: '778', fileName: 'Bahrain.png' },
  { country: 'Dominica', riik: 'Dominica', area: '751', fileName: 'Dominica.png' },
  { country: 'Tonga', riik: 'Tonga', area: '747', fileName: 'Tonga.png' },
  { country: 'Singapore', riik: 'Singapur', area: '728', fileName: 'Singapore.png' },
  {
    country: 'Federated States of Micronesia',
    riik: 'Mikroneesia',
    area: '702',
    fileName: 'Micronesia.png'
  },
  { country: 'Saint Lucia', riik: 'Saint Lucia', area: '616', fileName: 'Saint_Lucia.png' },
  { country: 'Andorra', riik: 'Andorra', area: '468', fileName: 'Andorra.png' },
  { country: 'Palau', riik: 'Belau', area: '459', fileName: 'Palau.png' },
  { country: 'Seychelles', riik: 'Seišellid', area: '452', fileName: 'Seychelles.png' },
  { country: 'Antigua and Barbuda', riik: 'Antigua ja Barbuda', area: '442', fileName: 'Antigua_and_Barbuda.png' },
  { country: 'Barbados', riik: 'Barbados', area: '430', fileName: 'Barbados.png' },
  {
    country: 'Saint Vincent and the Grenadines',
    riik: 'Saint Vincent ja Grenadiinid',
    area: '389',
    fileName: 'Saint_Vincent_and_the_Grenadines.png'
  },
  { country: 'Grenada', riik: 'Grenada', area: '344', fileName: 'Grenada.png' },
  { country: 'Malta', riik: 'Malta', area: '316', fileName: 'Malta.png' },
  { country: 'Maldives', riik: 'Maldiivid', area: '300', fileName: 'Maldives.png' },
  {
    country: 'Saint Kitts and Nevis',
    riik: 'Saint Kitts ja Nevis',
    area: '261',
    fileName: 'Saint_Kitts_and_Nevis.png'
  },
  { country: 'Marshall Islands', riik: 'Marshalli Saared', area: '181', fileName: 'Marshall_Islands.png' },
  { country: 'Liechtenstein', riik: 'Liechtenstein', area: '160', fileName: 'Liechtenstein.png' },
  { country: 'San Marino', riik: 'San Marino', area: '61', fileName: 'San_Marino.png' },
  { country: 'Tuvalu', riik: 'Tuvalu', area: '26', fileName: 'Tuvalu.png' },
  { country: 'Nauru', riik: 'Nauru', area: '21', fileName: 'Nauru.png' },
  { country: 'Monaco', riik: 'Monaco', area: '2', fileName: 'Monaco.png' },
  { country: 'Vatican City', riik: 'Vatikan', area: '1', fileName: 'Vatican.png' }
]

export default andmed
