import React from 'react'

const About = () => {
  return (
    <div className='about'>
      <div>Author: Siim Sepp</div>
      {/* <div style={{ marginTop: '1rem' }}>
        Code on
        <a href='#' target='_blank' rel='noreferrer'>
          {' '}
          GitHub
        </a>
      </div> */}
    </div>
  )
}

export default About
